import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import DropDown from "./Dropdown";
import Header from "./Header";
import { kl_logo } from "../common";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ThreeDots } from "react-loader-spinner";
import pLimit from "p-limit";

const useStyles = makeStyles({
  root: {
    minHeight: "100dvh",
    display: "flex",
    flexDirection: "column",
  },
});

const limit = pLimit(50);

// Function to fetch folder children with limited concurrency
const fetchFolderChildren = async ({ queryKey }) => {
  const [_, { accessToken, folderId, userId }] = queryKey;
  let collectedPptData = [];
  let nextLink = null;

  try {
    const response = await axios.get(
      nextLink || `https://graph.microsoft.com/v1.0/users/${userId}/drive/items/${folderId}/children`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const requests = response.data.value.map((element) =>
      limit(async () => {
        if (element.folder) {
          const childData = await fetchFolderChildren({
            queryKey: ["folderChildren", { accessToken, folderId: element.id, userId }],
          });
          collectedPptData = collectedPptData.concat(childData);
        } else if (element.file && element.name.endsWith(".pptx")) {
          const pptItem = await createPPTData(element, accessToken);
          collectedPptData.push(pptItem);
        }
      })
    );

    await Promise.all(requests);
  } catch (error) {
    console.log("Error While Fetching Folder", error);
  }
  return collectedPptData;
};

const createPPTData = async (item, accessToken) => {
  const { id: folderId, name: folderName, driveId } = item.parentReference;
  const { name, id } = item;
  const url = item["@microsoft.graph.downloadUrl"];
  const thumbnail = await fetchThumbnail(driveId, id, accessToken);

  return {
    folderId,
    folderName,
    fileName: name,
    fileId: id,
    fileUrl: url,
    thumbnail: thumbnail,
  };
};

// Function to handle thumbnails
const fetchThumbnail = async (driveId, itemId, accessToken) => {
  const thumbnailUrl = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}/thumbnails`;
  try {
    const response = await axios.get(thumbnailUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.value[0];
  } catch (error) {
    console.error("Error retrieving thumbnails:", error);
    return null;
  }
};

const Callback = () => {
  const { instance, accounts } = useMsal();
  const styles = useStyles();
  const [dataFetched, setDataFetched] = React.useState(false); // Track if data has been fetched
  const {
    data: pptData = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["folderChildren", { folderId: process.env.FOLDER_ID, userId: process.env.USER_ID }],
    queryFn: async () => {
      if (dataFetched) return pptData; // Return existing data if already fetched
      const accessTokenRequest = {
        scopes: ["User.Read", "Sites.Read.All"],
        account: accounts[0],
      };
      const response = await instance.acquireTokenSilent(accessTokenRequest);
      const accessToken = response.accessToken;

      const fetchedData = await fetchFolderChildren({
        queryKey: ["folderChildren", { accessToken, folderId: process.env.FOLDER_ID, userId: process.env.USER_ID }],
      });
      setDataFetched(true); // Mark data as fetched after the API call
      return fetchedData;
    },
    // Prevent refetching on window focus and mount
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !dataFetched, // This will prevent the query from automatically running
  });

  const groupedPptData = React.useMemo(() => {
    const groupedByFolder = pptData.reduce((acc, file) => {
      if (!acc[file.folderName]) {
        acc[file.folderName] = [];
      }
      acc[file.folderName].push(file);
      return acc;
    }, {});

    return Object.keys(groupedByFolder)
      .sort((a, b) => a.localeCompare(b)) // Sort folder names alphabetically
      .map((folderName) => ({
        folderName,
        // Sort files within each folder by fileName
        files: groupedByFolder[folderName],
      }));
  }, [pptData]);
  
  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className={styles.root}>
      <Header logo={kl_logo} message={accounts[0].name} setDataFetched={setDataFetched} />
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", height: "50dvh", alignItems: "center" }}>
          <ThreeDots visible={true} height="80" width="80" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
        </div>
      ) : (
        groupedPptData.map((item) => <DropDown key={item.folderName} title={item.folderName} itemArray={item.files} />)
      )}
    </div>
  );
};

Callback.propTypes = {
  title: PropTypes.string,
};

export default Callback;
