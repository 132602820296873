import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";

function DropdownItem({ item, handleSlide, open }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleInsertSlide = async (url) => {
    setIsLoading(true);
    await handleSlide(url);
    setIsLoading(false);
  };
  return (
    open && (
      <div className="item">
        {isLoading ? (
          <ThreeDots visible={true} height="80" width="80" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
        ) : (
          <>
            <img
              src={item?.thumbnail?.medium?.url}
              className="image"
              onClick={() => handleInsertSlide(item.fileUrl)}
              alt={`${item.fileName}`}
            />
            <label style={{ textTransform: "capitalize", fontWeight: "bold", textAlign: "center" }}>
              {item.fileName}
            </label>
          </>
        )}
      </div>
    )
  );
}

export default DropdownItem;
