import React from "react";
import { Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Callback from "./Callback";
import LoginButton from "./LoginButton";
import Header from "./Header";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div style={{ minHeight: "100dvh", backgroundColor: "#fff" }}>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <Callback />
        </QueryClientProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Header />
        <div className="login-page">
          <LoginButton />
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
