import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import "./index.css";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./authConfig";
import { HashRouter as Router } from "react-router-dom";
/* global document, Office, module, require */

// const title = "Kl Slides";
const rootElement = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;
const render = (Component) => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Component />
        </Router>
      </MsalProvider>
    </FluentProvider>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  render(App);
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
