import { useMsal } from "@azure/msal-react";
import { Button } from "@fluentui/react-components";
/* global document, Office, module, require */
import React from "react";
import logo from "../assets/images/microsoft.png";
const LoginButton = () => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    try {
      await instance.loginPopup({
        scopes: ["User.Read", "Sites.Read.All"],
        prompt: "select_account", // Force account selection
      });
    } catch (error) {
      if (error.errorCode === "user_cancelled") {
        console.warn("User cancelled the login flow.");
      } else {
        console.error("Login failed:", error);
      }
    }
  };

  return (
    <Button onClick={handleLogin} style={{ alignItems: "center" }}>
      <img src={logo} height={20} style={{ marginLeft: -5, marginRight: 5 }} />
      Login with Microsoft
    </Button>
  );
};

export default LoginButton;
