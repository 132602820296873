import React, { useState, useRef } from "react";
import { ChevronDownFilled, ChevronUpFilled } from "@fluentui/react-icons";
import { insertAllSlides } from "../taskpane";
import { Buffer } from "buffer";
import DropdownItem from "./DropdownItem";
const DropDown = ({ title, itemArray }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const getBase64PPT = async (url) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      return buffer.toString("base64");
    } catch (error) {
      console.error("Error converting PPT to base64:", error);
    }
  };
  const handleSlide = async (url) => {
    const base64 = await getBase64PPT(url);
    await insertAllSlides(base64);
  };
  const sortedItems = [...itemArray].sort((a, b) => a.fileName.localeCompare(b.fileName));
  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span style={{ textTransform: "uppercase" }}>{title}</span>
        {isOpen ? (
          <ChevronUpFilled fontSize={20} className="dropdown-icon" />
        ) : (
          <ChevronDownFilled fontSize={20} className="dropdown-icon" />
        )}
      </div>
      <div className={`gridCol ${isOpen ? "active" : ""}`}>
        {sortedItems.map((item) => (
          <DropdownItem key={item.fileId} item={item} handleSlide={handleSlide} open={isOpen} />
        ))}
      </div>
    </div>
  );
};

export default DropDown;
