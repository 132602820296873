import * as React from "react";
import PropTypes from "prop-types";
import { Image, tokens, makeStyles } from "@fluentui/react-components";
import { kl_logo } from "../common";
import { IconButton } from "@fluentui/react";
import logo from "../assets/images/image.png";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
  welcome__header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    paddingTop: "40px",
    backgroundColor: tokens.colorNeutralBackground3,
    position: "relative",
  },
  message: {
    paddingTop: 10,
    fontSize: tokens.fontSizeHero900,
    fontWeight: tokens.fontWeightRegular,
    fontColor: tokens.colorNeutralBackgroundStatic,
  },
});

const Header = (props) => {
  const { instance } = useMsal();
  const { title, message, setDataFetched } = props;
  const styles = useStyles();
  const handleLogout = () => {
    instance.logoutPopup();
    setDataFetched(false);
  };
  const isAuthenticated = useIsAuthenticated();
  return (
    <section className={styles.welcome__header}>
      {isAuthenticated && (
        <IconButton onClick={handleLogout} style={{ position: "absolute", right: 20, top: 10 }}>
          <img src={logo} alt="" height={30} />
        </IconButton>
      )}
      <Image width="90" style={{ borderRadius: "50%", padding: 10 }} height="90" src={kl_logo} alt={title} />
      <p className="message">{message}</p>
    </section>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
  setDataFetched: PropTypes.func,
};

export default Header;
