import { PublicClientApplication } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: process.env.AZURE_CLIENT_ID, // Replace with your Application (client) ID
    authority: `https://login.microsoftonline.com/${process.env.AZURE_TENANT_ID}`, // Replace with your Directory (tenant) ID
    redirectUri: "https://klslides.kadellabs.com/taskpane.html", // Replace with your redirect URI
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set to true if you are having issues on IE11 or Edge
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
