/* global PowerPoint console */
/* global Office  */
// import PowerPoint from '@microsoft/office-js/dist/powerpoint-15.js';

export async function insertText(text) {
  try {
    await PowerPoint.run(async (context) => {
      const slide = context.presentation.getSelectedSlides().getItemAt(0);
      const textBox = slide.shapes.addTextBox(text);
      textBox.fill.setSolidColor("white");
      textBox.lineFormat.color = "black";
      textBox.lineFormat.weight = 1;
      textBox.lineFormat.dashStyle = PowerPoint.ShapeLineDashStyle.solid;
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}

function getSelectedSlideIndex() {
  return new Promise((resolve, reject) => {
    Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value.slides[0].index);
      } else {
        reject(new Error(asyncResult.error.message));
      }
    });
  });
}

function getSelectedSlideID() {
  return new Promise((resolve, reject) => {
    Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value.slides[0].id);
      } else {
        reject(new Error(asyncResult.error.message));
      }
    });
  });
}
export async function addSlideWithMatchingLayout() {
  await PowerPoint.run(async function (context) {
    let selectedSlideIndex = await getSelectedSlideIndex();

    // Decrement the index because the value returned by getSelectedSlideIndex()
    // is 1-based, but SlideCollection.getItemAt() is 0-based.
    const realSlideIndex = selectedSlideIndex - 1;
    const selectedSlide = context.presentation.slides.getItemAt(realSlideIndex).load("slideMaster/id, layout/id");

    await context.sync();

    context.presentation.slides.add({
      slideMasterId: selectedSlide.slideMaster.id,
      layoutId: selectedSlide.layout.id,
    });

    await context.sync();
  });
}
export const addSlide = async () => {
  try {
    await PowerPoint.run(async (context) => {
      console.log(context);
      const presentation = context.presentation;
      const slides = presentation.slides;
      console.log(slides);
      // Add a new slide to the end of the presentation
      const newSlide = slides.add();
      console.log(newSlide);
      // Add a title and text to the slide
      // newSlide.shapes.getItemAt(0).textFrame.text = "New Slide Title";
      // newSlide.shapes.getItemAt(1).textFrame.text = "This is some content on the slide.";

      await context.sync();
      console.log("Slide added successfully.");
    });
  } catch (error) {
    console.error("Error adding slide:", error);
  }
};

export const addSlides = async (slidesData) => {
  try {
    await PowerPoint.run(async (context) => {
      const presentation = context.presentation;
      const slides = presentation.slides;

      // Loop through the array of slide data
      slidesData.forEach((slideData) => {
        const newSlide = slides.add();
        console.log(slides.add());
        // Set the title and content if provided
        if (slideData.title) {
          newSlide.shapes.getItemAt(0).textFrame.text = slideData.title;
        }

        if (slideData.content) {
          newSlide.shapes.getItemAt(1).textFrame.text = slideData.content;
        }
      });

      // Sync to apply the changes to the presentation
      await context.sync();
      console.log("Slides added successfully.");
    });
  } catch (error) {
    console.error("Error adding slides:", error);
  }
};

export async function insertAllSlides(pptBase64) {
  try {
    await PowerPoint.run(async function (context) {
      const selectedSlideID = await getSelectedSlideID();
      console.log(selectedSlideID);
      context.presentation.insertSlidesFromBase64(pptBase64, {
        targetSlideId: selectedSlideID + "#",
      });
      await context.sync();
    });
  } catch (error) {
    console.error("Error while inserting base64", error);
  }
}
